<template>
  <sdCards class="start__header">
    <div class="percent">
      <sdHeading as="h5" style="margin-right: auto">{{
        i18n.t(
          'accounts.token.sell',
          {
            round: i18n.t(`accounts.token.${percentage.round_name}`),
          },
          isCurrentRound ? 1 : 2,
        )
      }}</sdHeading>
      <p class="percent__switch" @click="switchRounds" v-if="percentage.completed_rounds.length">
        <span>{{ i18n.t('accounts.token.rounds', isCurrentRound ? 1 : 2) }}</span>
        <span v-if="isCurrentRound">
          ({{
            +percentage.completed_rounds[0].total +
            (percentage.completed_rounds[1] ? +percentage.completed_rounds[1].total : 0)
          }}
          MTH)
        </span>
      </p>
      <div class="percent__info" v-if="isCurrentRound">
        <TokenComponent :item="{ name: i18n.t('accounts.token.selled'), amount: percentage.sale }" />
        <TokenComponent :item="{ name: i18n.t('accounts.token.available'), amount: percentage.total }" />
      </div>
      <div class="percent__info" v-else>
        <TokenComponent
          v-for="item in percentage.completed_rounds"
          :item="{ name: i18n.t(`accounts.token.${item.name}`, 2), amount: item.total, price: item.price }"
          :key="item.name"
        />
      </div>
      <a-progress stroke-color="#20c997" style="width: 100%" :percent="+percentage.percent" v-if="isCurrentRound" />
      <a-progress stroke-color="#20c997" style="width: 100%" :percent="100" v-else />
    </div>
  </sdCards>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import TokenComponent from './TokenComponent';

export default {
  components: { TokenComponent },
  props: {
    percentage: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    const i18n = useI18n();
    const isCurrentRound = ref(true);
    const switchRounds = () => {
      isCurrentRound.value = !isCurrentRound.value;
    };

    return { isCurrentRound, switchRounds, i18n };
  },
};
</script>

<style scoped lang="scss">
.percent {
  &__switch {
    margin-right: auto;
    margin-bottom: 0;
    font-size: 12px;
    cursor: pointer;
    span:nth-child(1) {
      opacity: 0.5;
      text-decoration: underline;
    }
  }
}
</style>
