<template>
  <div>
    <p class="percent__title">
      {{ item.name }}
    </p>
    <a-tooltip placement="top" :title="`${i18n.t('accounts.trading.price')} $${item.price}`" v-if="item.price">
      <p class="percent__current">
        {{ i18n.n(parseInt(item.amount), 'numeric') }}
        <span> MTH</span>
      </p>
    </a-tooltip>
    <p class="percent__current" v-else>
      {{ i18n.n(parseInt(item.amount), 'numeric') }}
      <span> MTH</span>
    </p>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const i18n = useI18n();
    return {
      i18n,
    };
  },
};
</script>
